.backgroundResume{
    background: rgb(82,133,213);
    background: linear-gradient(0deg, rgba(82,133,213,1) 0%, rgba(255,255,255,1) 115%);
}
.backgroundHome{
    background: rgb(82,133,213);
    background: linear-gradient(0deg, rgba(82,133,213,1) 0%, rgba(255,255,255,1) 115%);
}

.backgroundHistory{
    background: rgb(82,133,213);
    background: linear-gradient(0deg, rgba(82,133,213,1) 0%, rgba(255,255,255,1) 115%);
}

.backgroundProjects{
    background: rgb(82,133,213);
    background: linear-gradient(0deg, rgba(82,133,213,1) 0%, rgba(255,255,255,1) 115%);
    padding-top: 1%;
    height: max-content;
}

.backgroundOther{
    background: rgb(82,133,213);
    background: linear-gradient(0deg, rgba(82,133,213,1) 0%, rgba(255,255,255,1) 115%);
}

// .ant-modal-body{ 
//     background-color: #B481EE;
// }

.ant-modal-footer {
    background-color: #B481EE;
}

.ant-modal-content { 
    background-color: #b481ee;
}