.centerAlign {
    text-align: center;
}

.unfinished{
    //height: 100%;
    //width: 60%;
    opacity: 0.5;
}

.inprogress{
    height: 100%;
    width: 60%;
    opacity: 1;
}