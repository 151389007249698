.starwars{
    //display: flex;
    width: 100%;
    align-content: center;
    padding: 5%;
}

.text{
    text-align: justify;
    padding-right: 2%;
    font-size: 17px;
}

.swRow{
    cursor: url('../PixelArt/LightsaberCursorBlue.png'), pointer;
}

.billiardsRow{
    cursor: url('../PixelArt/8BallCursor.png'), pointer;
}

.choirRow{
    cursor: url('../PixelArt/BassClefCursor.png'), pointer;
}

.gamesRow{
    cursor: url('../Games/Chess/Images/WhiteBishop.png'), pointer;
}
