.square{ 
    background-color: #EEE;
    border: 1px solid black;
    max-width: 80px;
    position: relative;
    .content{
        position: absolute;
        width:100%;
        height:100%;
    }
    &:after {
        content: '';
        display: block;
        padding-top: calc(100% * 1);
    }
}

.square-black{ 
    background-color: darkgray;
    border: 1px solid black;
    max-width: 80px;
    position: relative;
    .content{
        position: absolute;
        color: white;
        width:100%;
        height:100%;
    }
    &:after {
        content: "";
        align-content: center;
        display: block;
        padding-top: calc(100% * 1);
    }
}

.dumbSquare{
    flex: 1;
    background-color: #EEE;
    border: 1px solid black;
    
}

.dumbSquare-black{
    flex: 1;
    min-width: 5vw;
    min-height: 5vw;
    background-color: darkgrey;
    border: 1px solid black;
    
}
.chessPiece{
    height: 100%;
    width: 100%;
    text-align: center;
}