.profile {
    position: relative;
    height: 100%;
    width: 100%;
    opacity: 1;
    border-radius: 100%;
    
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-profile {
    background-color: transparent;
}

.profile:hover {
    transform: rotateY(180deg);
}

.background {
    height: 100%;
    width: 100%;
    opacity: 0.5;
    object-fit: none;
    object-position: 50% 100%;
}

.landscape {
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center -175px;

}

.banner {
    &-name {
        background-color: #0C2340;
        .ant-typography {
            margin-bottom: 0px;
        }
    }
    &-title {
        text-align: center;
        font-size: 14px;
        font-variant: 'tabular-nums';
    }  
} 

.menu {
    &-row{
        .ant-menu{
            
            background-color: #0C2340;
            color: white;
        }
        
    }
    &-bar{
        //Not doing anything
        margin: auto;
        .ant-menu-item {
            .ant-menu-item-active{
                color: #C41E3A;
            }
            
            .ant-menu-item-selected{
                color: #C41E3A;
            }
        }
    }
    
}